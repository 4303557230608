/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */

// ==========================================================================
// Print styles.
// Inlined to avoid the additional HTTP request: h5bp.com/r
// ==========================================================================

*,
*:before,
*:after {
    font-family: Arial, Helvetica, sans-serif;
    background: transparent !important;
    color: #000 !important; // Black prints faster: h5bp.com/s
    box-shadow: none !important;
    text-shadow: none !important;
}

a,
a:visited {
    text-decoration: none;
}

a[href]:after {
    content: ' (' attr(href) ')';
    font-style: italic;
    text-decoration: underline;
}

abbr[title]:after {
    content: ' (' attr(title) ')';
}

// Don't show links that are fragment identifiers,
// or use the `javascript:` pseudo protocol
a[href^='#']:after,
a[href^='javascript:']:after {
    content: '';
}

pre,
blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
}

thead {
    display: table-header-group; // h5bp.com/t
}

tr,
img {
    page-break-inside: avoid;
}

img {
    max-width: 100% !important;
}

p,
h2,
h3 {
    orphans: 3;
    widows: 3;
}

h2,
h3 {
    page-break-after: avoid;
}

// Bootstrap specific changes start

// Bootstrap components
.navbar {
    display: none;
}
.btn,
.dropup > .btn {
    > .caret {
        border-top-color: #000 !important;
    }
}
.label {
    border: 1px solid #000;
}

.table {
    border-collapse: collapse !important;

    td,
    th {
        background-color: #fff !important;
    }
}
.table-bordered {
    th,
    td {
        border: 1px solid #ddd !important;
    }
}

// Bootstrap specific changes end

/*!
     * FullCalendar v2.6.0 Print Stylesheet
     * Docs & License: http://fullcalendar.io/
     * (c) 2015 Adam Shaw
     */

.fc {
    max-width: 100% !important;
}

/* Global Event Restyling
    --------------------------------------------------------------------------------------------------*/

.fc-event {
    background: #fff !important;
    color: #000 !important;
    page-break-inside: avoid;
}

.fc-event .fc-resizer {
    display: none;
}

/* Table & Day-Row Restyling
    --------------------------------------------------------------------------------------------------*/

th,
td,
hr,
thead,
tbody,
.fc-row {
    border-color: #ccc !important;
    background: #fff !important;
}

/* kill the overlaid, absolutely-positioned components */
/* common... */
.fc-bg,
    .fc-bgevent-skeleton,
    .fc-highlight-skeleton,
    .fc-helper-skeleton,
    /* for timegrid. within cells within table skeletons... */
    .fc-bgevent-container,
    .fc-business-container,
    .fc-highlight-container,
    .fc-helper-container {
    display: none;
}

/* don't force a min-height on rows (for DayGrid) */
.fc tbody .fc-row {
    height: auto !important; /* undo height that JS set in distributeHeight */
    min-height: 0 !important; /* undo the min-height from each view's specific stylesheet */
}

.fc tbody .fc-row .fc-content-skeleton {
    position: static; /* undo .fc-rigid */
    padding-bottom: 0 !important; /* use a more border-friendly method for this... */
}

.fc tbody .fc-row .fc-content-skeleton tbody tr:last-child td {
    /* only works in newer browsers */
    padding-bottom: 1em; /* ...gives space within the skeleton. also ensures min height in a way */
}

.fc tbody .fc-row .fc-content-skeleton table {
    /* provides a min-height for the row, but only effective for IE, which exaggerates this value,
           making it look more like 3em. for other browers, it will already be this tall */
    height: 1em;
}

/* Undo month-view event limiting. Display all events and hide the "more" links
    --------------------------------------------------------------------------------------------------*/

.fc-more-cell,
.fc-more {
    display: none !important;
}

.fc tr.fc-limited {
    display: table-row !important;
}

.fc td.fc-limited {
    display: table-cell !important;
}

.fc-popover {
    display: none; /* never display the "more.." popover in print mode */
}

/* TimeGrid Restyling
    --------------------------------------------------------------------------------------------------*/

/* undo the min-height 100% trick used to fill the container's height */
.fc-time-grid {
    min-height: 0 !important;
}

/* don't display the side axis at all ("all-day" and time cells) */
.fc-agenda-view .fc-axis {
    display: none;
}

/* don't display the horizontal lines */
.fc-slats,
.fc-time-grid hr {
    /* this hr is used when height is underused and needs to be filled */
    display: none !important; /* important overrides inline declaration */
}

/* let the container that holds the events be naturally positioned and create real height */
.fc-time-grid .fc-content-skeleton {
    position: static;
}

/* in case there are no events, we still want some height */
.fc-time-grid .fc-content-skeleton table {
    height: 4em;
}

/* kill the horizontal spacing made by the event container. event margins will be done below */
.fc-time-grid .fc-event-container {
    margin: 0 !important;
}

/* TimeGrid *Event* Restyling
    --------------------------------------------------------------------------------------------------*/

/* naturally position events, vertically stacking them */
.fc-time-grid .fc-event {
    position: static !important;
    margin: 3px 2px !important;
}

/* for events that continue to a future day, give the bottom border back */
.fc-time-grid .fc-event.fc-not-end {
    border-bottom-width: 1px !important;
}

/* indicate the event continues via "..." text */
.fc-time-grid .fc-event.fc-not-end:after {
    content: '...';
}

/* for events that are continuations from previous days, give the top border back */
.fc-time-grid .fc-event.fc-not-start {
    border-top-width: 1px !important;
}

/* indicate the event is a continuation via "..." text */
.fc-time-grid .fc-event.fc-not-start:before {
    content: '...';
}

/* time */

/* undo a previous declaration and let the time text span to a second line */
.fc-time-grid .fc-event .fc-time {
    white-space: normal !important;
}

/* hide the the time that is normally displayed... */
.fc-time-grid .fc-event .fc-time span {
    display: none;
}

/* ...replace it with a more verbose version (includes AM/PM) stored in an html attribute */
.fc-time-grid .fc-event .fc-time:after {
    content: attr(data-full);
}

/* Vertical Scroller & Containers
    --------------------------------------------------------------------------------------------------*/

/* kill the scrollbars and allow natural height */
.fc-scroller,
    .fc-day-grid-container,    /* these divs might be assigned height, which we need to cleared */
    .fc-time-grid-container {
    /* */
    overflow: visible !important;
    height: auto !important;
}

/* kill the horizontal border/padding used to compensate for scrollbars */
.fc-row {
    border: 0 !important;
    margin: 0 !important;
}

/* Button Controls
    --------------------------------------------------------------------------------------------------*/

.fc-button-group,
.fc button {
    display: none; /* don't display any button-related controls */
}

/* Toolkit Stylesheet */

button,
.embed-responsive,
.quicklinks,
.masthead,
.site-search,
.local-header,
.nav-priority,
.site-nav,
.breadcrumb,
.column-container-secondary,
.multi-page-nav,
.multi-page-footer,
.site-footer-upper,
.site-footer-middle,
.site-footer-lower,
.footer-twitter-feed,
[class*='rs-img'],
iframe,
.tk-nav {
    display: none;
}

.column-container-primary {
    width: 100%;
}

[class*='wrapper'] {
    width: 100% !important;
    max-width: none !important;
}

/**
     * Typography
     */

body {
    font-family: Arial, Helvetica, sans-serif;
}

.heading-underline {
    text-align: left !important;
}

blockquote {
    background: none !important;
    border: 0 !important;
    padding: 0 !important;
}

/* Accordions
    --------------------------------------------------------------------------------------------------*/
.js .accordion-content,
.accordion-body {
    display: block !important;
}

/* Courses */
.col-right,
.sticky-navbar {
    display: none !important;
}

#skip-main,
#return-to-search-results {
    display: none;
}

.back-top {
    display: none;
}

/* Publication Accordions
    ---------------------------------------------------------------------------------------------------*/
#symplectic div {
    display: block !important;
}